module.exports = {
  "ok": "OK",
  "apply": "Apply",
  "save": "Save",
  "back": "Back",
  "cancel": "Cancel",
  "clearInput": "Clear input",
  "close": "Close",
  "finish": "Finish",
  "confirm.title": "Confirm",
  "contextHelp.title": "Help",
  "moreActions": "More functions ...",
  "defaultErrorMessage": "Please contact the application administrator.",
  "error": "Error",
  "execute": "Execute",
  "delete": "Delete",
  "dialog.close.title": "Close",
  "dialog.noEmbeddedFrames": "Your browser doesn't support embedded frames.",
  "no": "No",
  "share": "Share",
  "warning": "Warning",
  "yes": "Yes",
  "continue": "Continue",
  "noMatchesFound": "No matches found",
  "noResult": "Your query didn't return any results. Please change the filter values.",
  "select2.selectionTooBig_one": "Only one item can be selected.",
  "select2.selectionTooBig_other": "Only {{count}} items can be selected.",
  "select2.placeholder": "Enter a search term",
  "error.validate.title": "Error validating input",
  "error.validate.message": "An error occurred while validating input."
}