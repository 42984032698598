import { rgb2lab, lab2rgb } from 'rgb-lab';

import type { Color, HexColor, Rgb } from 'cadenza/utils/colors/colors';
import { parseColor, rgbToHsl, toHexColor } from 'cadenza/utils/colors/colors';

/*
 * Our code depends on some theme properties being defined,
 * but the theme.js should be empty by default.
 * So we apply the defaults in here.
 */
const THEME_DEFAULTS: Partial<Theme> = {
  'primary-color': '#2370b7',

  'main-header-invert': true,
  'view-header-invert': false,

  'login-invert': true,
  'login-aside': true,

  'worksheet-bg': '#ffffff',
  'workbook-view-bg': '#ffffff',
  'workbook-view-border': false,
  'workbook-add-layer-button-primary': false,

  features: {
    'nav-tree-highlight-last-clicked-node': false,
  },
};

const CUSTOMIZABLE_COLORS = ['default-color', 'link-color', 'visited-link-color', 'primary-color'];

const OTHER_CUSTOMIZABLE_PROPS = [
  'default-font-family',
  'heading-font-family',
  'main-header-bg',
  'view-header-bg',
  'main-header-height',
  'login-bg',
  'login-banner-height',
];

const THEME_FEATURE_NAMES = [
  /* ******
   * Misc *
   ********/

  /* tag::themejs-features[]
`nav-tree-highlight-last-clicked-node`::
+
Controls the highlighting of the last clicked item in the navigation tree.
+
With this feature enabled, when a user clicks on an item in the navigation tree (not a folder),
it becomes highlighted. The highlighting remains until another item is clicked in the same browser tab.
In different browser tabs, different items can be highlighted.
+
*Default value:* `false`

end::themejs-features[] */
  'nav-tree-highlight-last-clicked-node',
] as const;

export type ThemeFeatureName = (typeof THEME_FEATURE_NAMES)[number];

export function isThemeFeature(featureName: string): featureName is ThemeFeatureName {
  return THEME_FEATURE_NAMES.includes(featureName as never);
}

export interface Theme {
  /* ********
   * Colors *
   **********/

  /* tag::themejs-properties-colors[]
`default-color` – The default text color::
+
Many more UI colors like the background of the map controls are derived from that.
Set it to e.g. `#ff0000` (red) to get an overview what is affected.
+
This property has a big impact on the accessibility of Cadenza Web and
hence should be changed with care. Only solid, dark colors should be used.
+
*Default value:* `'#353B44'`

end::themejs-properties-colors[] */
  'default-color': Color;

  /* tag::themejs-properties-colors[]
`link-color` – The link color::
+
*Default value:* `'#195aa6'`

end::themejs-properties-colors[] */
  'link-color': Color;

  /* tag::themejs-properties-colors[]
`visited-link-color` – The color of visited links::
+
*Default value:* `'#660099'`

end::themejs-properties-colors[] */
  'visited-link-color': Color;

  /* tag::themejs-properties-colors[]
`primary-color` – The primary brand color::
+
This color is used for headings and highlighting.
Be sure to choose a rather dark color, which works for headings on a light background
and as background color for white text.
+
This property is important for the communication of highlighting to the user.
So it is important for both, accessibility and branding.
+
If you're unsure, what "dark color" means: The
https://www.w3.org/WAI/standards-guidelines/wcag/[Web Content Accessibility Guidelines (WCAG)]
recommend a contrast ratio between text and background color of at least (!) 3:1.
Check the contrast ratio of lightgray text and your `primary-color` here:
https://contrast-ratio.com/#%23ebeced-on-%232370B7
+
*Default value:* `'#2370b7'`

end::themejs-properties-colors[] */
  'primary-color': Color;

  /* ************
   * Typography *
   **************/

  /* tag::themejs-properties-typography[]
`default-font-size` – The default font size::
+
This should usually not be changed, but it's useful if you changed the
`default-font-family` (see below) to a font, which _looks_ smaller or bigger.
+
*Default value:* `'14px'`

end::themejs-properties-typography[] */
  'default-font-size': string;

  /* tag::themejs-properties-typography[]
`default-font-family` – The default font family::
+
*Default value:* `'"Titillium Web", Arial, Helvetica, sans-serif'`

end::themejs-properties-typography[] */
  'default-font-family': string;

  /* tag::themejs-properties-typography[]
`default-font-icon-offset` – An offset to fix the vertical alignment of icons when a `default-font-family` is defined::
+
Depending on the metrics of the default font (ascender, descender, etc.) the vertical alignment of
icons that are floating in the text may not be correct. To fix this, you can define a factor for the
`default-font-size` with which the icons are translated vertically.
+
If no `default-font-family` is defined, icons are translated slightly to the top.
+
*Default value:* `-0.1` (0 if a `default-font-family` is defined.)

end::themejs-properties-typography[] */
  'default-font-icon-offset': number;

  /* tag::themejs-properties-typography[]
`heading-font-family` – The font family for accentuating headings::
+
*Default value:* `'"Titillium Web", Arial, Helvetica, sans-serif'`

end::themejs-properties-typography[] */
  'heading-font-family': string;

  /* ************
   * Navigation *
   **************/

  /* tag::themejs-properties-navigation[]
`main-header-bg` and `view-header-bg` – Backgrounds of the main and view navigation headers::
+
You can use any valid CSS background value, which is supported in all relevant browsers
(see the Cadenza Web system requirements). These are for example colors, images or gradients.
+
These properties have a big visual impact and hence are important for branding.
+
*Default value `main-header-bg`:* _Same as_ `primary-color` +
*Default value `view-header-bg`:* `'#ffffff'`
+
More info on the CSS background property: https://developer.mozilla.org/docs/Web/CSS/background

end::themejs-properties-navigation[] */
  'main-header-bg': Color;
  'view-header-bg': Color;

  /* tag::themejs-properties-navigation[]
`main-header-invert` and `view-header-invert` – Make the text color of the navigation bars white::
+
If `main-header-bg` or `view-header-bg` are rather dark, you should consider activating `main-header-invert`
or `view-header-invert` respectively for better readability. In this case, the `default-color`
setting is overridden.
+
*Default value `main-header-invert`:* `true` +
*Default value `view-header-invert`:* `false`

end::themejs-properties-navigation[] */
  'main-header-invert': boolean;
  'view-header-invert': boolean;

  /* tag::themejs-properties-navigation[]
`main-header-height` – The height of the main navigation header::
+
*Default value:* `'40px'`

end::themejs-properties-navigation[] */
  'main-header-height': string;

  /* *******
   * Login *
   *********/

  /* tag::themejs-properties-login[]
`login-center` – Center the login form and its contents on the screen::
+
*Default value:* `false`
+
The `login-aside` property is ignored if this is true.

end::themejs-properties-login[] */
  'login-center': boolean;

  /* tag::themejs-properties-login[]
`login-bg` – The background color of the login page::
+
*Default value:* _Same as_ `primary-color`

end::themejs-properties-login[] */
  'login-bg': Color;

  /* tag::themejs-properties-login[]
`login-invert` – Make the text color of the login form white::
+
*Default value:* `true`

end::themejs-properties-login[] */
  'login-invert': boolean;

  /* tag::themejs-properties-login[]
`login-aside` – Show the optional panel aside the login form::
+
Set this to true if you added contents to the `accessAside` template.
This property is ignored if `login-center` is true.
+
*Default value:* `true`

end::themejs-properties-login[] */
  'login-aside': boolean;

  /* tag::themejs-properties-login[]
`login-banner-height` – The height of the login banner::
+
Set this if you added contents to the `loginBanner` template.
+
*Default value:* `'0px'`

end::themejs-properties-login[] */
  'login-banner-height': string;

  /* ***********
   * Workbooks *
   *************/

  /* tag::themejs-properties-workbooks[]
`worksheet-bg` – The background color of worksheets::
+
*Default value:* `#ffffff`

end::themejs-properties-workbooks[] */
  'worksheet-bg': HexColor;

  /* tag::themejs-properties-workbooks[]
`workbook-view-border` – Show the border of workbook views::
+
*Default value:* `false`

end::themejs-properties-workbooks[] */
  'workbook-view-border': boolean;

  /* tag::themejs-properties-workbooks[]
`workbook-view-bg` – The background color of workbook views::
+
*Default value:* `#ffffff`

end::themejs-properties-workbooks[] */
  'workbook-view-bg': HexColor;

  /* tag::themejs-properties-workbooks[]
`workbook-add-layer-button-primary` – Make Add layer button variant "primary"::
+
*Default value:* `false`

end::themejs-properties-workbooks[] */
  'workbook-add-layer-button-primary': boolean;

  /* **********
   * Features *
   ************/

  features: { [key in ThemeFeatureName]?: boolean };
}

export function theme<P extends Exclude<keyof Theme, 'features'>>(prop: P) {
  return window.Disy.theme[prop];
}

/**
 * Applies global properties of the Theming API.
 *
 * _Note:_ Use-case specific (non-global) properties are applied in related code.
 *
 * @param theme - The theme properties
 */
export function applyThemeProperties(theme: Partial<Theme>) {
  Object.assign(theme, THEME_DEFAULTS, {
    ...theme,
    features: {
      ...THEME_DEFAULTS.features,
      ...(theme.features ?? {}),
    },
  });
  CUSTOMIZABLE_COLORS.forEach((prop) => applyColor(theme, prop));
  OTHER_CUSTOMIZABLE_PROPS.forEach((prop) => applyProp(theme, prop));
  applyFontSize(theme['default-font-size']);
  applyIconOffset(theme['default-font-family'] != null, theme['default-font-icon-offset']);
}

function applyColor(theme: Record<string, unknown>, prop: string) {
  const customValue = theme[prop] as Color;
  if (customValue) {
    const rgb = parseColor(customValue).slice(0, 3) as Rgb;
    applyPropToDoc(`${prop}-rgb`, rgb.join());

    if (prop === 'primary-color') {
      const [h, s, l] = rgbToHsl(rgb);
      [15, 30].forEach((dl) => applyPropToDoc(`${prop}-darken-${dl}`, `hsl(${h}deg, ${s * 100}%, ${l * (100 - dl)}%)`));
    } else if (prop.endsWith('link-color')) {
      const [, a, b] = rgb2lab(rgb);
      applyPropToDoc(`${prop}-light`, toHexColor(lab2rgb([90, a, b])));
    }
  }
}

function applyProp(theme: Record<string, unknown>, prop: string) {
  const customValue = theme[prop] as string;
  if (customValue) {
    applyPropToDoc(prop, customValue);
  }
}

const DEFAULT_FONT_SIZE = 'font-size-2';
const FONT_SIZES_COUNT = 9;

function applyFontSize(value?: string) {
  if (!value) {
    return;
  }

  const fontSize = parseFloat(value);
  const documentStyle = getComputedStyle(document.documentElement);
  const defaultFontSize = getPropAsNumber(documentStyle, DEFAULT_FONT_SIZE);
  const fontSizeRatio = fontSize / defaultFontSize;
  for (let i = 1; i <= FONT_SIZES_COUNT; i++) {
    const prop = `font-size-${i}`;
    const calculatedFontSize = fontSizeRatio * getPropAsNumber(documentStyle, prop);
    applyPropToDoc(prop, `${calculatedFontSize}px`);
  }
}

function applyIconOffset(isDefaultFontFamilyDefined: boolean, value = isDefaultFontFamilyDefined ? 0 : undefined) {
  if (value != null) {
    applyPropToDoc('default-font-icon-offset', String(value));
  }
}

function getPropAsNumber(style: CSSStyleDeclaration, prop: string) {
  return parseFloat(style.getPropertyValue(`--${prop}`));
}

function applyPropToDoc(prop: string, value: string) {
  document.documentElement.style.setProperty(`--${prop}`, value);
}
