import { assert } from 'cadenza/utils/custom-error';
import { getCurrentLocale } from 'cadenza/utils/i18n/i18n';
import type { ImmutableDate } from 'cadenza/utils/date-time/immutable-date';

import i18n from './format.properties';

/*
 * This module exports formatting functions which do not use format tokens (i.e. no DecimalFormat or SimpleDateFormat).
 * Having them in one place makes it easy to pick them up.
 */

// Numbers
const KM_TO_MILES_MULTIPLIER = 0.621371;
const METERS_TO_FEET_MULTIPLIER = 3.2808399;
const SQ_KM_TO_SQ_MILES_MULTIPLIER = 0.386102;
const SQ_METERS_TO_SQ_FEET_MULTIPLIER = 10.76391;

interface FormatNumberOptions {
  useGrouping?: boolean;
  minimumIntegerDigits?: number;
  fractionDigits?: number;
  minimumFractionDigits?: number;
  maximumFractionDigits?: number;
  style?: Intl.NumberFormatOptionsStyle;
}

// https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/NumberFormat
export function formatNumber(
  number: number,
  {
    useGrouping = true,
    minimumIntegerDigits = 1,
    fractionDigits,
    minimumFractionDigits = 0,
    maximumFractionDigits = Math.max(minimumFractionDigits, 3),
    style,
  }: FormatNumberOptions = {},
) {
  assert(Number.isFinite(number), `number must be finite: ${number}`);

  if (fractionDigits !== undefined) {
    minimumFractionDigits = fractionDigits;
    maximumFractionDigits = fractionDigits;
  }

  return number.toLocaleString(getCurrentLocale(), {
    useGrouping,
    minimumIntegerDigits,
    maximumFractionDigits,
    minimumFractionDigits,
    style,
  });
}

export function formatInteger(number: number, options: FormatNumberOptions = {}) {
  return formatNumber(number, { ...options, fractionDigits: 0 });
}

export function formatPercent(value: number, options: FormatNumberOptions = {}) {
  return formatNumber(value, { ...options, style: 'percent', fractionDigits: 0 });
}

// Date, Time, Duration

export function formatDate(date: ImmutableDate) {
  return date.toLocaleDateString(getCurrentLocale(), {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
  });
}

/**
 * Format date using ISO format.
 * Example output: "2024-01-17"
 * @param date
 */
export function formatIsoDate(date: ImmutableDate) {
  return date.toISOString().slice(0, 10);
}

interface TimeFormatOptions {
  second?: boolean;
}

export function formatTime(date: ImmutableDate, { second = true }: TimeFormatOptions = {}) {
  return date.toLocaleTimeString(getCurrentLocale(), {
    hour12: false,
    hour: '2-digit',
    minute: '2-digit',
    second: second ? '2-digit' : undefined,
  });
}

export function formatDateTime(date: ImmutableDate, options: TimeFormatOptions = {}) {
  return formatDate(date) + ' ' + formatTime(date, options);
}

export function formatRelativeTime(value: number, unit: Intl.RelativeTimeFormatUnit) {
  if (Object.is(value, -0) && ['second', 'seconds', 'minute', 'minutes'].includes(unit)) {
    return i18n('relativeTime.minusZero');
  }
  return new Intl.RelativeTimeFormat(getCurrentLocale(), { numeric: 'auto' }).format(value, unit);
}

/**
 * Formats duration to a string in format 'HH:MM:SS'
 *
 * @param duration - duration in seconds to format
 * @return formatted duration
 */
export function formatDurationToHHMMSS(duration: number) {
  assert(duration >= 0, `duration must be positive: ${duration}`);
  const hours = Math.floor(duration / 3600) % 3600;
  const minutes = Math.floor(duration / 60) % 60;
  const seconds = duration % 60;
  return `${pad2(hours)}:${pad2(minutes)}:${pad2(seconds)}`;
}

function pad2(number: number) {
  return formatInteger(number, { minimumIntegerDigits: 2 });
}

/**
 * Formats time duration to h, min, s.ss format (for example "1h 30min", "1h", "30min 5s", "2.5s")
 *
 * Note: this and formatDurationToHHMMSS began as a simple formatting function and grew with new options.
 * We hope that we can move some complexity to `Intl.DurationFormat` as soon as browsers pick up support for this API.
 *
 * @param durationInMilliseconds - The duration for format in milliseconds
 * @param [options] - Options
 * @param [options.includeMilliseconds=false] - If true, a milliseconds field is included (if it is not 0)
 * @param [options.includeSeconds=false] - If true, a seconds field is included (if it is not 0)
 * @param [options.includeDays=false] - If true, a days field is included (if it's not 0)
 * @param [options.style='narrow'] - The style of the output format, 'narrow' returns the short format (m, h, s etc.) and long returns the long format (hour, minute, second etc.)
 * @see {@link https://github.com/tc39/proposal-intl-duration-format}
 * @return The formatted duration
 * @example
 * formatDuration(5 * 60 * 1000); // returns '5min'
 * formatDuration(60 * 60 * 1000); // returns '1h'
 * formatDuration(65 * 60 * 1000); // returns '1h 5min'
 * formatDuration(3.5 * 60 * 1000, { includeSeconds = true }); // returns '3min 30s'
 * formatDuration(63.5 * 60 * 1000); // returns '1h 3min 30s'
 * formatDuration(172800 * 1000, {includeDays: true}) // returns '2d'
 * formatDuration(300 * 1000, {style: 'long'}) // returns '5 minutes'
 * formatDuration(250, {includeSeconds: true}) // returns '0.25s'
 */
export function formatDuration(
  durationInMilliseconds: number,
  {
    includeMilliseconds = false,
    includeSeconds = false,
    includeDays = false,
    style = 'narrow',
  }: {
    includeMilliseconds?: boolean;
    includeSeconds?: boolean;
    includeDays?: boolean;
    style?: 'narrow' | 'long';
  } = {},
) {
  assert(durationInMilliseconds >= 0, `duration must be positive: ${durationInMilliseconds}`);
  includeSeconds ||= includeMilliseconds; // `includeMilliseconds` implies `includeSeconds`

  const isLong = style === 'long';
  let roundedDuration = durationInMilliseconds;
  if (!includeSeconds) {
    roundedDuration = Math.round(durationInMilliseconds / 60000) * 60000;
  }
  if (roundedDuration === 0) {
    if (isLong) {
      return i18n(String(includeSeconds ? 'seconds' : 'minutes'), { count: 0 });
    }
    return includeSeconds ? '0s' : '0min';
  }
  const { days, hours, minutes, seconds } = calculateDurationParts(roundedDuration, includeMilliseconds);
  const includeD = includeDays && days > 0;
  const hoursValue = includeDays ? hours : days * 24 + hours;
  const includeH = hoursValue > 0;
  const includeS = includeSeconds && seconds > 0;
  const includeMin = minutes > 0 || (includeS && includeH);
  const formattedValues = [
    includeD && (isLong ? i18n('days', { count: days }) : `${days}d`),
    includeH && (isLong ? i18n('hours', { count: hoursValue }) : `${hoursValue}h`),
    includeMin && (isLong ? i18n('minutes', { count: minutes }) : `${minutes}min`),
    includeS &&
      (isLong ?
        i18n('seconds', { count: seconds, formattedCount: formatNumber(seconds) })
      : `${formatNumber(seconds)}s`),
  ];
  return formattedValues.filter(Boolean).join(' ');
}

function calculateDurationParts(durationInMilliseconds: number, includeMilliseconds: boolean) {
  const days = Math.floor(durationInMilliseconds / 86400000);
  const hours = Math.floor(durationInMilliseconds / 3600000) % 24;
  const minutes = Math.floor(durationInMilliseconds / 60000) % 60;
  let seconds = (durationInMilliseconds % 60000) / 1000;
  if (!includeMilliseconds) {
    seconds = Math.round(seconds);
  }
  return { days, hours, minutes, seconds };
}

// Length, Area

const KM = 1000;
const KM2 = KM * KM;

/**
 * Format a length (or distance) value in meters.
 * When the value is smaller or equal to 1000, the value is formatted in meters, rounded to 2 decimals.
 * When the value is greater than 1000, and unless the alwaysMeters flag is set to true,
 * the value is formatted in km rounded to 2 decimals.
 *
 * @param length - A floating point number representing a length in meters
 * @param [alwaysMeters=false] - A flag to force the format to be in meters event when the value is above 1 km
 * @param [roundToMeters=false] - A flag to indicate the value should be rounded to a
 * @param [metric=true] - If true show metic units, if false show imperial units
 * 1 meter precision and the decimal points for meters values omitted
 * @return The formatted value
 */
export function formatLength(length: number, alwaysMeters = false, roundToMeters = false, metric = true) {
  assert(Number.isFinite(length), `length must be finite: ${length}`);
  const bigFormat = (metric ? length : kmToMiles(length)) / KM;
  const smallFormat = metric ? length : meterToFeet(length);
  if (length > KM && !alwaysMeters) {
    return formatDecimalMessage(metric ? 'lengthKm' : 'lengthMile', bigFormat);
  }
  return formatDecimalMessage(metric ? 'lengthM' : 'lengthFeet', smallFormat, roundToMeters ? 0 : 2);
}

export function formatArea(area: number, metric = true) {
  assert(Number.isFinite(area), `area must be finite: ${area}`);
  const bigFormat = (metric ? area : sqKmToSqMiles(area)) / KM2;
  const smallFormat = metric ? area : sqMeterToSqFeet(area);
  return (
      area > 0.1 * KM2 // switch from m² to km² when > 100.000 m²
    ) ?
      formatDecimalMessage(metric ? 'areaKm' : 'areaMile', bigFormat)
    : formatDecimalMessage(metric ? 'areaM' : 'areaFeet', smallFormat);
}

function formatDecimalMessage(message: string, value: number, fractionDigits = 2) {
  return i18n(message, { value: formatNumber(value, { fractionDigits }) });
}

function kmToMiles(km: number) {
  return km * KM_TO_MILES_MULTIPLIER;
}
function meterToFeet(m: number) {
  return m * METERS_TO_FEET_MULTIPLIER;
}
function sqKmToSqMiles(km: number) {
  return km * SQ_KM_TO_SQ_MILES_MULTIPLIER;
}
function sqMeterToSqFeet(m: number) {
  return m * SQ_METERS_TO_SQ_FEET_MULTIPLIER;
}

// File Size

const FILE_SIZES = ['B', 'KB', 'MB', 'GB', 'TB'];

/**
 * Formats a file size
 *
 * @param size - A number of bytes
 * @example
 * // returns '1 kB'
 * formatFileSize(1024)
 * @return The formatted file size
 */
export function formatFileSize(size: number) {
  const i = Math.floor(Math.log(size) / Math.log(1024));
  return formatNumber(size / Math.pow(1024, i), { maximumFractionDigits: 2 }) + ' ' + FILE_SIZES[i];
}

// String

/**
 * Formats a list of strings.
 *
 * @example
 *   // returns 'a, b, and c'
 *   formatList([ 'a', 'b', 'c' ])
 *   // returns 'a, b, or c'
 *   formatList([ 'a', 'b', 'c' ], { type: 'disjunction' })
 *   // return 'a, b, c'
 *   formatList([ 'a', 'b', 'c' ], { type: 'unit' })
 * @param list
 * @param options - See [`Intl.ListFormat` constructor options on MDN](https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/ListFormat/ListFormat#parameters)
 * @return The formatted list
 */
export function formatList(list: string[], options?: Intl.ListFormatOptions) {
  return new Intl.ListFormat(getCurrentLocale(), options).format(list);
}

/**
 * Adds a suffix for ordinals to a number.
 * Examples
 * German: 1 -> 1., 2 -> 2., ... 10 -> 10.
 * English: 1 -> 1st, 2 -> 2nd, ... 10 -> 10th
 *
 * @param num - The number to add the suffix to.
 * @return - The number with suffix.
 */
export function makeOrdinal(num: number) {
  const rules = new Intl.PluralRules(getCurrentLocale(), { type: 'ordinal' });
  const suffix = i18n(`ordinal.${rules.select(num)}`);
  return `${num}${suffix}`;
}

/**
 * Wraps a string in quotes.
 *
 * _Note:_ This utility does _not_ consider nested quotes.
 *
 * @example
 *   // returns '"test"'
 *   quote('test')
 * @param string
 * @return The quoted string
 */
export function quote(string: string) {
  return `"${string}"`; // We use "dumb" (non-typographic) quotes, which are not locale-specific.
}

/**
 * Returns a copy of the provided string with the first letter capitalized.
 * If a nullish value including empty string is provided, the same value is returned.
 *
 * @param text - The text to capitalize
 * @return The capitalized text
 */
export function capitalizeFirstLetter(text?: string) {
  return text ? text.charAt(0).toUpperCase() + text.slice(1) : text;
}
