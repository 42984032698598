// eslint-disable import/no-webpack-loader-syntax
import { isFeatureAvailable } from 'cadenza/features';
import { logger } from 'cadenza/utils/logging';
import { formatNumber } from 'cadenza/utils/format/format';

import commonMessages from '!properties-loader!./common_messages.properties';
import commonMessagesEn from '!properties-loader!./common_messages_en.properties';

const commonPrefix = 'common:';
const customPrefix = 'custom:';
const NBSP = ' ';

const debugI18n = new URLSearchParams(location.search).has('debugI18n');

/**
 * This function is what you get when importing a `.properties` file with i18n messages.
 *
 * _Note:_ If you do changes here, you probably need to do similar changes to `PebbleI18Function.java`.
 *
 * @param {string} key - The key of the message to localize
 * @param {object} options - key/value pairs, which should be replaced in the message text.
 *   - The "count" key should have a numeric value: It's used to select the plural variant of a message.
 *   - The "defaultText" is used if the message key is not included in the `.properties` file.
 * @return {string} The localized message
 * @see <a href="https://conf.disy.net/x/8YEbAw">I18n in Cadenza Web</a> in the wiki
 */
export function loaderI18n(key = '', options = {}) {
  if (key.startsWith(commonPrefix)) {
    return commonI18n(key.substr(commonPrefix.length), options);
  }
  if (key.startsWith(customPrefix)) {
    return customI18n(key.substr(customPrefix.length), options);
  }

  if ('count' in options) {
    const count = options.count;
    const category = selectPluralizationCategory(count);
    const keyCandidate = `${key}_${category}`;
    if (this[keyCandidate]) {
      key = keyCandidate;
    } else {
      key = `${key}_other`;
    }
  }

  if (debugI18n) {
    return key;
  }

  let text = this[key] ?? options.defaultText;
  if (text == null) {
    logger.error(`Unknown message key "${key}"`);
    return `???${key}???`;
  }

  if (!isFeatureAvailable('SHOW_I18N_TILDES')) {
    text = text.replace(/^~/, '');
  }

  // replace three dots with ellipsis
  text = text.replace(/\s*(\.{3,}|…)/g, `${NBSP}…`);

  return Object.entries(options).reduce(
    (result, [option, value]) =>
      result.replace(new RegExp(`{{${option}}}`, 'g'), typeof value === 'number' ? formatNumber(value) : value),
    text,
  );
}

/**
 * Selects the pluralization category of the given number.
 *
 * _Note:_ Although we use the `Intl.LDMLPluralRule` values, we do not use the `Intl.PluralRules` API,
 * because for example with the 'en' locale we want to select 'zero' for 0 instead of 'other'.
 *
 * @param {number} n - A number
 * @return {Intl.LDMLPluralRule} The pluralization category of the number
 */
function selectPluralizationCategory(n) {
  switch (n) {
    case 0:
      return 'zero';
    case 1:
      return 'one';
    // Custom: new Intl.PluralRules('de-DE' / 'en_EN').select(2) suggests 'other' instead. 'two' is
    // part of the type Intl.LDMLPluralRule, though, so totally fine.
    case 2:
      return 'two';
  }
  return 'other';
}

/**
 * Can be used for i18n of "common" messages.
 *
 * The idea to reuse "common" messages like "OK" didn't work out,
 * because it happened to often that an "OK" button should actually
 * be labelled with "Submit" or similar. That's why this API is deprecated.
 *
 * @see common-messages.properties
 * @deprecated Create a separate `.properties` file for your use case instead
 *   and copy the "common" messages you need to there.
 * @param {string} key - The key of the message to localize
 * @param {object} [options] - key/value pairs, which should be replaced in the message text.
 *   The "count" key should have a numeric value: It's used to select the plural variant of a message.
 * @return {string} The localized message
 */
export function commonI18n(key, options) {
  return loaderI18n.call(getCommonMessages(), key, options);
}

export function getCurrentLanguage() {
  return window.Disy.language;
}

export function getCurrentLocale() {
  return window.Disy.locale;
}

function getCommonMessages() {
  return getCurrentLanguage() === 'en' ? commonMessagesEn : commonMessages;
}

function customI18n(key, options) {
  return loaderI18n.call(window.Disy.customMessages, key, options);
}

let collator;

/** @see net/disy/commons/core/collator/CadenzaCollator.java */
export function compareWithLocale(a, b) {
  if (!collator) {
    collator = new Intl.Collator(getCurrentLocale());
  }
  return collator.compare(a, b);
}

// for testing
export function __unsetCollator__() {
  collator = undefined;
}
