import { isThemeFeature, type ThemeFeatureName } from 'cadenza/theming-api';
import { assert } from 'cadenza/utils/custom-error';
import type { FeatureName } from 'cadenza/model/feature-name';
import { isFeatureName } from 'cadenza/model/feature-name';

const UI_FEATURE_NAMES = [
  /**
   * @see Published {@link https://jira.disy.net/browse/CADENZA-33416} Customers may use this to
   * deactivate the designer
   */
  'workbook-design', // allow showing designer
  'workbook-new-view', // show new view button
  'workbook-data-browser', // allow showing data browser
  'workbook-data-view-content', // allows to edit data-view content in the designer
  'workbook-access-settings', // show access settings button
  'workbook-more-actions', // show more ... button in header
  'workbook-snapshots', // show buttons for creating and selecting snapshots
  'workbook-save', // show save/save and publish/save as
  'workbook-worksheet-menu', // show drop-down menu for worksheets
  'workbook-select-version', // allow selecting the version when clicking the versions tag
  'workbook-view-titlebar', // show the title bar of views
  'workbook-view-titlebar-buttons', // show the title bar buttons of views
  'workbook-analysis-context', // enable the analysis context
  'workbook-analysis-context-closeable', // allows to close the analysis context, also shows the close button. On small screens (XS) the analysis context is always closeable
  'workbook-condition-management', // allows to edit/add/remove conditions in views and global analysis context
  'workbook-show-filtered-views', // shows which views are affected by filters
  'workbook-map-toolbar', // show map toolbar
  'workbook-map-find-address', // show find address action
  'workbook-map-find-coordinate', // show find coordinate action
  'workbook-map-find-what3words', // show find what3words action
  'workbook-map-measure', // show measure action
  'workbook-map-location-finder', // show location finder
  'workbook-map-routing', // show button for routing
  'workbook-map-isochrones', // show button for isochrones
  'workbook-map-timeline-player', // show button for timeline player
  'workbook-map-timeline-3d', // show button for 3d view
  'workbook-map-data-acquisition', // show buttons to create/edit/delete datasets in the map
  'workbook-map-add-layer-from-catalog', // show button to add a layer from the catalog in the map
  'workbook-map-add-layer-from-navigator', // show button to add a classic layer from the navigator in the map
  'workbook-map-add-layer-from-geodata-service', // show button to add a layer from a geodata service in the map
  'workbook-map-add-data-view-layer', // show button to add a data view layer in the map
  'workbook-map-add-group-layer', // show button to add a group layer in the map
  'workbook-map-add-sketch-layer', // show button to add a sketch layer in the map
  'workbook-map-add-grid-square-layer', // show button to add a grid square layer in the map
  'workbook-map-add-layer-from-data-import', // show button to add a layer from imported data in the map
  'workbook-map-add-data-acquisition-layer', // show button to add a layer from data acquisition template
  'workbook-map-add-analytics-extension-layer', // show button to add a layer from analytics extension

  /**
   * @see Published {@link https://jira.disy.net/browse/CADENZA-33416}  Customers may use this to
   * deactivate editing of WB layout/design
   */
  'workbook-view-management', // allows to edit/add/remove views, data views
  'workbook-drillthrough-same-workbook', // show drillthroughs to the same workbook
  'workbook-drillthrough-other-workbooks', // show drillthroughs to other workbooks
  'workbook-drillthrough-external-links', // show drillthroughs to external links
  'workbook-drillthrough-postmessage', // show drillthroughs sent via postMessage
  // export
  'workbook-print-worksheet',
  'workbook-worksheet-pdf-export',
  'workbook-view-pdf-export',
  'workbook-view-data-and-images-export',
  // allows to create/duplicate/edit worksheet, click on version tag
  'workbook-management',
  'workbook-layout-management', // allows to edit workbook layout, like resizing and dragging views
  'workbook-data-view-analysis-context', // allows displaying the analysis context of a data view
  'workbook-map-data-export', // allows exporting map data ('KML_EXPORT', 'SHAPEFILE_EXPORT', 'EXPORT_GEOPACKAGE')
  'workbook-object-info-address', // enables geocoding functionality in object info flyout
  'workbook-table-cell-details',
  'workbook-table-single-measure-values',
  'report-management',
  'workbook-zip-export',
  'spatial-condition-management',
] as const;

export type UiFeatureName = (typeof UI_FEATURE_NAMES)[number];
export type WorkbookUiFeatureName = UiFeatureName & `workbook-${string}`;

export type AllFeatures = FeatureName | UiFeatureName | ThemeFeatureName;

/**
 * The keys must not be of type FeatureName, because the dependencies
 * between the ICadenzaFeatures are modeled already in the backend.
 */
type FeatureDependencies = { [key in AllFeatures]?: AllFeatures[] };

/**
 * Defines which features depend on others.
 * When adding new dependent features here, please check that there are not loops - features
 * should not depend on each other.
 * Example:
 * 'workbook-view-management': [ 'workbook-design' ] means that 'workbook-view-management' is available only when 'workbook-design' is.
 */
const FEATURE_DEPENDENCIES: FeatureDependencies = {
  'workbook-analysis-context-closeable': ['workbook-analysis-context'],
  'workbook-zip-export': ['workbook-management', 'WORKBOOK_ZIP_EXPORT_IMPORT'],
  'report-management': ['workbook-management', 'workbook-view-management', 'EDIT_REPORT'],
  'spatial-condition-management': ['workbook-view-management', 'WORKBOOK_EDIT_SPATIAL_CONDITION'],
  'workbook-access-settings': ['workbook-management'],
  'workbook-data-view-content': ['workbook-design'],
  'workbook-layout-management': ['workbook-design'],
  'workbook-new-view': ['workbook-view-management'],
  'workbook-save': ['workbook-management'],
  'workbook-select-version': ['workbook-management'],
  'workbook-snapshots': ['WORKBOOK_SNAPSHOTS_USE'],
  'workbook-view-management': ['workbook-design'],
  'workbook-management': ['workbook-design'],
  'workbook-map-find-address': ['workbook-map-toolbar', 'REVERSE_GEOCODING'],
  'workbook-map-find-coordinate': ['workbook-map-toolbar'],
  'workbook-map-find-what3words': ['workbook-map-toolbar', 'WHAT3WORDS'],
  'workbook-map-measure': ['workbook-map-toolbar'],
  'workbook-map-location-finder': ['workbook-map-toolbar', 'LOCATION_FINDER'],
  'workbook-map-routing': ['workbook-map-toolbar', 'ROUTING'],
  'workbook-map-isochrones': ['workbook-map-toolbar', 'ROUTING_ISOCHRONES'],
  'workbook-map-timeline-player': ['workbook-map-toolbar', 'TIMELINE'],
  'workbook-map-timeline-3d': ['workbook-map-toolbar', 'TIMELINE_3D'],
  'workbook-map-data-acquisition': ['workbook-map-toolbar', 'ACQUIRE_OBJECTTYPE_DATA'],
  'workbook-map-add-layer-from-data-import': ['SELF_SERVICE_IMPORT'],
  'workbook-map-add-layer-from-geodata-service': ['ADD_EXTERNAL_LAYERS'],
  'workbook-map-add-layer-from-navigator': ['ADD_LAYER_FROM_NAVIGATOR'],
  'workbook-map-add-sketch-layer': ['CREATE_SKETCH_LAYER'],
  'workbook-map-add-data-acquisition-layer': ['CREATE_OBJECTTYPE_BASED_ON_TEMPLATE'],
  'workbook-map-add-analytics-extension-layer': ['ANALYTICS_EXTENSIONS'],
  'workbook-worksheet-pdf-export': ['CREATE_WORKBOOK_REPORT'],
  'workbook-view-pdf-export': ['CREATE_WORKBOOK_REPORT'],
  'workbook-object-info-address': ['REVERSE_GEOCODING'],
  ROUTING: ['LOCATION_FINDER'],
  ROUTING_ISOCHRONES: ['LOCATION_FINDER'],
};

/**
 * Checks the availability of a Cadenza feature for the current user.
 *
 * The feature must be enabled on platform level and can be disabled on page, theme or request level.
 * Unfortunately, the feature names are different on each level, and we cannot change that, because
 * some are published. So we also check the synonyms of the given feature name. If the feature
 * depends on other features, then all other features must be available too.
 *
 * NOTE: Doesn't check workbook features - also in dependencies workbook features are omitted.
 * If you are in workbook, then isWorkbookFeatureAvailable is preferred unless you have a good
 * reason to not check workbook feature dependencies.
 *
 * @param featureName - The name of the feature to check (on any level)
 * @return Whether the feature is available.rt
 */
export function isFeatureAvailable(featureName: AllFeatures): boolean {
  assert(
    !isWorkbookFeature(featureName),
    'Cannot check workbook feature with a isFeatureAvailable. Use isWorkbookFeatureAvailable instead.',
  );
  return (
    isSingleFeatureAvailable(featureName) &&
    getDependentFeatures(featureName)
      .filter((dependentFeatureName) => !isWorkbookFeature(dependentFeatureName))
      .every(isFeatureAvailable)
  );
}

export function getDependentFeatures(featureName: AllFeatures) {
  return FEATURE_DEPENDENCIES[featureName] ?? [];
}

/**
 * Checks if feature is available without checking dependencies. Should always be a private function.
 * @param featureName
 */
export function isSingleFeatureAvailable(featureName: AllFeatures) {
  const isPlatform = isFeatureName(featureName);
  const isPage = isUiFeature(featureName);
  const isTheme = isThemeFeature(featureName);

  // Assertion, because this is called also from JS code.
  assert(isPlatform || isPage || isTheme, `Unknown feature "${featureName}"`);

  return (
    (!isPlatform || isFeatureAvailableInPlatform(featureName)) &&
    (!isPage || isFeatureAvailableInPage(featureName)) &&
    (!isTheme || isFeatureAvailableInTheme(featureName))
  );
}

function isUiFeature(featureName: string): featureName is UiFeatureName {
  return UI_FEATURE_NAMES.includes(featureName as never);
}

export function isWorkbookFeature(feature: string): feature is WorkbookUiFeatureName {
  return isUiFeature(feature) && feature.startsWith('workbook');
}

function isFeatureAvailableInTheme(featureName: ThemeFeatureName) {
  return window.Disy.theme.features[featureName] !== false;
}

function isFeatureAvailableInPage(featureName: UiFeatureName) {
  return !window.Disy.disabledUiFeatures.includes(featureName);
}

function isFeatureAvailableInPlatform(featureName: FeatureName) {
  return window.Disy.availableFeatures?.includes(featureName) ?? false;
}
